<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getReportReplacementStatements"
  :isLoading="isLoading"
  :actions="actions"
  :saveReport="getReportReplacementStatementsExcel"
)
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'sailor.id', text: this.$t('tableHeaders.sailor_id'), sortable: false },
        { value: 'sailorFullName', text: this.$t('tableHeaders.sailor_full_name'), sortable: false },
        { value: 'number', text: this.$t('tableHeaders.number'), sortable: false },
        { value: 'created_at', text: this.$t('tableHeaders.created_at'), sortable: false },
        { value: 'type_document', text: this.$t('tableHeaders.type_document'), sortable: false },
        { value: 'rank', text: this.$t('tableHeaders.rank'), sortable: false },
        { value: 'position', text: this.$t('tableHeaders.position'), sortable: false },
        { value: 'status_document', text: this.$t('tableHeaders.status_document'), sortable: false },
        { value: 'event', text: this.$t('actions'), sortable: false }
      ],
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.go',
          action: (item) =>
            this.$router.push({
              name: 'qualification-replacement-statement-info',
              params: { id: item.sailor.id, statementId: item.id }
            }),
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: true
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.report.replacementStatementQualification,
      isLoading: (state) => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getReportReplacementStatements', 'getReportReplacementStatementsExcel'])
  }
}
</script>
